import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { Project05 } from './container';

function ShowPage() {
  return (
    <>
      <Project05 />
    </>
  );
}

export default ShowPage;
