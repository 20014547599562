import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { Project07 } from './container';

function ShowPage() {
  return (
    <>
      <Project07 />
    </>
  );
}

export default ShowPage;
